export class MiniBasket {

    private readonly storageKey: string;
    private readonly amountElement: HTMLElement | null;
    private readonly linkElement: HTMLElement | null;
    private readonly storage?: Storage;

    constructor() {
        this.storageKey = "or_miniBasketAmount";
        this.amountElement = document.querySelector<HTMLElement>('.order_js_minibasket_amount');
        this.linkElement = document.querySelector<HTMLElement>('.order_js_minibasket_link');
        this.storage = this.createStorage();

        window.addEventListener("storage", (event: StorageEvent) => {
            if (event.key === this.storageKey && event.newValue) {
                const amount = this.parseStorageData(event.newValue);
                if (amount !== undefined) {
                    this.renderAmount(amount);
                }
            }
        });

        o_global.eventQBus.on("ft1.order-core.cleanMiniBasketAmountStorage", () => {
            this.cleanStorage();
        });

        window.o_order.common.basketChange.onBasketChange((maybeEvent?: Event) => {
            this.update(maybeEvent);
        });

        window.o_global.eventLoader.onAllScriptsExecuted(50, () => {
            if (this.amountElement) {
                this.failSafeSendMergeToTrackingServer();
            }
        });

        /*🤫*/
        window.setTimeout(() => {
            this.update();
        }, 10);
    }

    private createStorage(): Storage | undefined {
        try {
            return new window.o_global.Storage(window.localStorage);
        } catch (ignored) {
            /*🤫*/
            return undefined;
        }
    }

    private getVid(): string | undefined {
        return window.o_util.cookie.get("visitorId");
    }

    private isLoggedIn(): boolean {
        try {
            return window.o_user.loginState.presenter.isLoggedIn();
        } catch (e) {
            console.error('could not determine login state', e)
            return false;
        }
    }

    private failSafeSendMergeToTrackingServer() {
        /*🤫*/
        const amount = !!window.o_order.orderConfirmation ? 0 : this.getAmount();
        window.o_order.tracking.addToPageImpression({order_BasketItems: [(amount ?? 0).toString()]});
    }

    private saveToStorage(amount: number): number {
        const data: StorageData = {
            amount: amount,
            vid: this.getVid(),
            expire: new Date().getTime() + 300000,
            loggedIn: this.isLoggedIn()
        };

        this.storage?.setItem(this.storageKey, JSON.stringify(data));

        return amount;
    }

    private cleanStorage(): void {
        this.storage?.removeItem(this.storageKey);
    }

    private parseStorageData(dataString: string): number | undefined {
        try {
            const data = JSON.parse(dataString) as StorageData | null;
            if (data &&
                data.expire > new Date().getTime() &&
                data.vid === this.getVid() &&
                data.loggedIn === this.isLoggedIn()) {

                return data.amount;
            }

        } catch (ignored) {
            /*🤫*/
        }

        return undefined;
    }

    private checkStorage(maybeEvent?: Event): number | undefined {
        if (!maybeEvent) {
            const storedAmount = this.storage?.getItem(this.storageKey);
            if (storedAmount) {
                return this.parseStorageData(storedAmount);
            }
        }

        return undefined;
    }

    private checkHtml(): number | undefined {
        const maybeAmountElements = document.getElementsByClassName("or_js_miniBasketAmount");

        if (maybeAmountElements.length) {
            const amount = Number(maybeAmountElements[0].getAttribute("data-minibasketamount"));
            if (!isNaN(amount)) {
                return this.saveToStorage(amount);
            }
        }

        return undefined;
    }

    private getAmount(maybeEvent?: Event): number | undefined {
        return this.checkHtml() || this.checkStorage(maybeEvent);
    }

    private update(maybeEvent?: Event) {
        const amount = this.getAmount(maybeEvent);
        if (amount !== undefined) {
            this.renderAmount(amount);
        } else {
            if (this.amountElement) {
                this.loadAmount()
                    .then(loadedAmount => this.saveToStorage(loadedAmount))
                    .then(savedAmount => this.renderAmount(savedAmount));
            }
        }
    }

    private loadAmount(): Promise<number> {
        if (this.amountElement) {
            const url = this.amountElement.getAttribute("data-loadurl");
            if (url) {
                return window.o_util.ajax.getJSON(url).then((xhr) => {
                    switch (xhr.status) {
                        case 200:
                            return this.extractAmount(xhr.responseJSON as AmountResponse | undefined);
                        default:
                            return 0;
                    }
                });
            }
        }

        return Promise.resolve(0);
    }

    private extractAmount(response: AmountResponse | undefined): number {
        return response?.amount ?? 0;
    }

    private renderAmount(amount: number): void {
        if (this.amountElement) {
            this.setAmount(amount);
            this.updateTrackingJSON(amount);
        }
    }

    private setAmount(amount: number) {
        if (this.amountElement) {
            const emptyClass = 'or_minis__badge--empty';
            this.amountElement.innerHTML = amount.toString();
            if (amount > 0) {
                this.amountElement.classList.remove(emptyClass);
            } else {
                this.amountElement.classList.add(emptyClass);
            }
        }
    }

    private updateTrackingJSON(amount: number) {
        if (this.linkElement) {
            const trackingString = this.linkElement.getAttribute("data-ts-link") as string;
            const trackingJSON = JSON.parse(trackingString);
            trackingJSON.order_MiniBasket = amount;
            this.linkElement.setAttribute("data-ts-link", JSON.stringify(trackingJSON));
        }
    }

    static initialize(): void {
        window.o_order.miniBasket = window.o_order.miniBasket ?? new MiniBasket();
    }
}

interface StorageData {
    vid: string | undefined;
    amount: number;
    expire: number;
    loggedIn: boolean;
}

interface AmountResponse {
    amount: number;
}

declare global {
    interface Window {
        o_user: {
            loginState: {
                presenter: {
                    isLoggedIn(): boolean;
                };
            };
        };
    }
}
